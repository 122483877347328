import { Form, Input, Button, notification } from "antd"
import styles from "./login.module.scss"
import logo from "@/assets/img/logo_black.png"
import { useRef, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { setStore } from "@/utils/store"
import IconFont from "@/icons/index"
import request, { getWholeUrl } from "@/utils/request"
import { getVerifyCode, loginByPwd } from "@/utils/api"
import { getGuid } from "@/utils/random"
import loading from '@/utils/loading'

function Login() {
  const formEl = useRef()
  const history = useHistory()
  const [verifyCode, setVerifyCode] = useState()
  // 登录参数
  const [loginParams,setLoginParams] = useState({})

  useEffect(() => {
    getCode()
  }, [])

  // 获取验证码
  async function getCode() {
    let uuid = getGuid()
    setLoginParams({...loginParams,uuid:uuid})
    setVerifyCode(getWholeUrl({ ...getVerifyCode, params: { uuid: uuid } }))
  }

  /**
   * 监听文本框输入
   * @param {String} key form表单字段
   */
  function handleInput(key,e) {
    let {value} = e.target
    setLoginParams({...loginParams,[key]:value})
  }

  function login() {
    let form = formEl.current
    form
      .validateFields()
      .then(async (res) => {
        loading(true)
        let loginRes = await request({...loginByPwd,data: loginParams})
        loading(false)
        if(loginRes && loginRes.data.code == 200) {
          let userInfo = loginRes.data.data
          setStore("token",userInfo.token)
          setStore("userInfo",JSON.stringify(userInfo))
          setStore("permission",JSON.stringify(userInfo.permissionList))
          history.replace("/")
        } else {
          notification.error({
            description: loginRes.data.msg
          })
          if(loginRes.data.code == 501) {
            getCode()
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div className={styles.parent}>
      <div className={styles.shadow}></div>
      <div className={styles.loginPanel}>
        <div className={styles.title}>
          <img className={styles.logo} src={logo} alt="logo"></img>
          <div className={styles.tips}>拉克艺后台管理系统</div>
        </div>
        <Form ref={formEl} className={styles.form} wrapperCol={{ offset: 2, span: 20 }} name="basic" size="large">
          <Form.Item name="username" rules={[{ required: true, message: "请输入您的账号!" }]}>
            <Input className={styles.inputItem} prefix={<IconFont type="icon-zhanghao" className={styles.icon} />} placeholder="账号" onInput={(e)=>handleInput('username',e)}/>
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: "请输入您的密码!" }]}>
            <Input.Password className={styles.inputItem}  prefix={<IconFont type="icon-mimasuolock" className={styles.icon} />} placeholder="密码" onInput={(e)=>handleInput("password",e)}/>
          </Form.Item>
          <Form.Item name="code" rules={[{ required: true, message: "请输入验证码!" }]}>
            <div className={styles.verifyCodePanel}>
              <Input className={`${styles.verifyCodeInput} ${styles.inputItem}`} prefix={<IconFont type="icon-yanzhengyanzhengma" className={styles.icon} />} placeholder="验证码" onInput={(e)=>handleInput("verifyCode",e)}/>
              <img className={styles.verifyCode} src={verifyCode} alt="验证码" onClick={getCode}></img>
            </div>
          </Form.Item>
        </Form>
        <Button className={styles.loginBtn} type="primary" onClick={login} size="large">登录</Button>
      </div>
    </div>
  )
}

export default Login
