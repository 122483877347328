import { BrowserRouter } from 'react-router-dom'
// 引入导航守卫，代替switch功能。并做权限校验
import GlobalGuard from '@/auth/globalGuard'
import { Spin } from 'antd'
import { useState } from 'react'
import { subscribe,getState } from '@/redux/store'

function App() {

    const [spining, setSpining] = useState(false)

    /**
     * 监听redux中的SHOW_LOADING
     */
    subscribe(()=>{
        setSpining(getState().showLoading)
    })

    return (
        <div>
            <Spin spinning={spining} size="large" wrapperClassName="loading" className="spin">
                <BrowserRouter>
                    <GlobalGuard />
                </BrowserRouter>
            </Spin>
        </div>
    )
}
export default App

