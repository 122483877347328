import { createStore } from "redux"
/**
 * 项目仅仅使用redux并非react-redux
 * action名称
 */
export const IS_ADD_DYNAMIC_ROUTER_STATUS = "is_add_dynamic_router_status"
export const SHOW_LOADING = "show_loading"


// 仓库默认值
const defaultState = {
    isAddDynamic: false,
    showLoading: false
}

const reducer = (state = defaultState, action) => {
    let { type, payload } = action
    switch (type) {
        case IS_ADD_DYNAMIC_ROUTER_STATUS:
            return {...state,isAddDynamic:payload};
        case SHOW_LOADING:
            return {...state,showLoading:payload};
        default:
            return defaultState;
    }
}

let store = createStore(reducer)

export const { dispatch, subscribe, getState } = store
