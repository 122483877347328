import React from 'react'
import { withRouter,Redirect,Route } from 'react-router-dom'
import Login from '@/pages/login/login'
import Main from '@/pages/main/main'
import NotFound from '@/pages/not_found/notFound'
import { routes,addDynamicRoutes } from '@/router/index'
import { findRouteTrace } from '@/utils/util'
import { getState } from '@/redux/store'

import * as StoreUtil from '@/utils/store'

function GlobalGuard(props) {
    // 当前访问的地址链接
    let { location:{ pathname } } = props
    // 验证是否登录
    if(StoreUtil.getToken()) {
        if(pathname === "/login") {
            // 登录状态下，重新访问登录页面的时候，则重定向到首页;访问"/"根路径的时候也重定向到首页
            return <Redirect to="/"></Redirect>
        } else {
            // 已经登录，则判断是否添加动态路由，没有添加则进行添加操作
            if(!getState().isAddDynamic) {
                // 添加动态路由
                let dynamicRoute = JSON.parse(StoreUtil.getStore("permission"))
                addDynamicRoutes(dynamicRoute)
            }
            // 查找当前浏览器链接对应的路由，以及路由的绝对路径
            let { findResult:targetRoute, pathTrace} = findRouteTrace(routes,pathname)
            // 因为 routes 中方便配置采用的是路由嵌套的方式，所以存在相对的path属性，需要转换为绝对路径，然后设置<Route>标签的path属性
            let absolutePath = pathTrace.map((item)=>item.path).join("/")
            if(targetRoute) {
                let { component,meta: { exact } } = targetRoute
                return <Main><Route path={ absolutePath } exact={ exact } component={ component }></Route></Main>
            } else {
                return <NotFound></NotFound>
            }
        }
    } else {
        if(pathname === '/login') {
            return <Login></Login>
        } else {
            return <Redirect to="/login"></Redirect>
        }
    }
}

const routerGlobalGuard = withRouter(GlobalGuard)

export default routerGlobalGuard