import styles from "./notFound.module.scss"
import IconFont from "@/icons/index"
import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
function NotFound() {
    const history = useHistory()
    let [countDown, setCountDown] = useState(10)

    useEffect(() => {
        startCountDown()
    }, [])

    function startCountDown() {
        let intervalId = setInterval(() => {
            setCountDown(--countDown)
            if (countDown == 0) {
                clearInterval(intervalId)
                toHome()
            }
        }, 1000)
    }

    function toHome() {
        history.replace("/")
    }

    return (
        <div className={styles.container}>
            <IconFont className={styles.icon} type="icon-ku" />
            <div className={styles.desc}>~呜呜呜,访问的资源不存在（{<div className={styles.number}>{countDown}s</div>}）后将自动跳转至首页</div>
            <div className={styles.btn} onClick={toHome}>返回首页</div>
        </div>
    )
}

export default NotFound
