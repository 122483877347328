
// ########################### 登录 #################

/**
 * 获取图片验证码
 */
export let getVerifyCode = {
  url: "login/getCode",
  method: "get",
}

/**
 * 账号密码登录
 */
export let loginByPwd = {
  url: "login/byPwd",
  method: "post",
}

// ########################### 数据统计 #################

export let queryYearOrderInfo = {
  url: "analyse/queryYearOrderInfo",
  method: "get"
}

export let queryAllOrderAndMoney = {
  url: "analyse/queryAllOrderAndMoney",
  method: "get"
}

// ########################### 客户管理 #################

/**
 * 查询客户列表
 */
export let getCustomerList = {
  url: "customer/queryCustomers",
  method: "post"
}

/**
 * 更新客户状态
 */
export let updateCustomerStatus = {
  url: 'customer/updateStatus',
  method: "get"
}

/**
 * 查询客户反馈信息
 */
export let getFeedback = {
  url: "feedback/queryFeedback",
  method: "post"
}

// ########################### 设备管理 #################

/**
 * 查询分类树
 */
export let queryDeviceCategoryTree = {
  url: "device/queryDeviceCategory",
  method: "get"
}

/**
 * 新增分类
 */
export let addDeviceCategory = {
  url: "device/addDeviceCategory",
  method: "post"
}

/**
 * 删除分类
 */
export let delDeviceCategory = {
  url: "device/delDeviceCategory",
  method: "get"
}

/**
 * 更新分类
 */
export let updateDeviceCategory = {
  url: "device/updateDeviceCategory",
  method: "post"
}

/**
 * 查询设备列表
 */
export let queryDeviceList = {
  url: "device/queryDeviceList",
  method: "post"
}

/**
 * 更新设备
 */
export let updateDevice = {
  url: "device/updateDevice",
  method: "post"
}

/**
 * 查询设备详情
 */
export let getDeviceDetail = {
  url: "device/getDeviceDetail",
  method: "get"
}

/**
 * 分页查询属性分类列表
 */
export let queryAttrCategoryList = {
  url: "device/queryAttrCategoryList",
  method: "post"
}

/**
 * 更新属性分类
 */
export let updateAttrCategory = {
  url: "device/updateAttrCategory",
  method: "post"
}

/**
 * 新增属性分类
 */
 export let addAttrCategory = {
  url: "device/addAttrCategory",
  method: "post"
}

/**
 * 删除属性分类
 */
 export let delAttrCategory = {
  url: "device/delAttrCategory",
  method: "get"
}

/**
 * 查询指定属性分类的所有值
 */
 export let queryAttrCategoryValue = {
  url: "device/queryAttrCategoryValue",
  method: "get"
}

/**
 * 更新属性分类值
 */
export let updateAttrCategoryValue = {
  url: "device/updateAttrCategoryValue",
  method: "post"
}

/**
 * 新增属性分类值
 */
 export let addAttrCategoryValue = {
  url: "device/addAttrCategoryValue",
  method: "post"
}

/**
 * 删除属性分类值
 */
 export let delAttrCategoryValue = {
  url: "device/delAttrCategoryValue",
  method: "get"
}

/**
 * 查询所有的属性分类
 */
export let queryAllAttrCategory = {
  url: "device/queryAllAttrCategory",
  method: "get"
}

/**
 * 新增规格
 */
export let addDeviceAttr = {
  url: "device/addDeviceAttr",
  method: "post"
}

/**
 * 删除规格
 */
export let delDeviceAttr = {
  url: "device/delDeviceAttr",
  method: "get"
}

/**
 * 更新设备规格
 */
export let updateDeviceAttr = {
  url: "device/updateDeviceAttr",
  method: "post"
}

// ########################### 样式排版 #################

/**
 * 查询订单列表
 */
export let queryOrderList = {
  url: "order/getOrderList",
  method: "post"
}

/**
 * 查询订单详情
 */
export let queryOrderDetail = {
  url: "order/queryOrderDetail",
  method: "get"
}

/**
 * 查询订单轨迹
 */
export let queryOrderTrace = {
  url: "order/getOrderTrace",
  method: "get"
}

/**
 * 新增订单轨迹
 */
 export let addOrderTrace = {
  url: "order/addOrderTrace",
  method: "post"
}

/**
 * 删除订单轨迹
 */
 export let delOrderTrace = {
  url: "order/delOrderTrace",
  method: "get"
}


// ########################### 样式排版 #################

/**
 * 查询轮播图列表
 */
export let getBannerList = {
  url: "compose/getBannerList",
  method: "get"
}

/**
 * 更新轮播图
 */
export let updateBanner = {
  url: "compose/updateBanner",
  method: "post"
}

/**
 * 删除轮播图
 */
export let removeBanner = {
  url: "compose/delBanner",
  method: "get"
}

/**
 * 新增轮播图
 */
export let addBanner = {
  url: "compose/addBanner",
  method: "post"
}

/**
 * 查询视频列表
 */
 export let getVideoList = {
  url: "compose/getVideoList",
  method: "get"
}

/**
 * 更新视频
 */
export let updateVideo = {
  url: "compose/updateVideo",
  method: "post"
}

/**
 * 删除视频
 */
export let removeVideo = {
  url: "compose/delVideo",
  method: "get"
}

/**
 * 新增视频
 */
export let addVideo = {
  url: "compose/addVideo",
  method: "post"
}

/**
 * 查询服务类目列表
 */
 export let getServiceCategoryList = {
  url: "compose/getServiceCategoryList",
  method: "get"
}

/**
 * 更新服务类目
 */
export let updateServiceCategory = {
  url: "compose/updateServiceCategory",
  method: "post"
}

/**
 * 删除服务类目
 */
export let removeServiceCategory = {
  url: "compose/delServiceCategory",
  method: "get"
}

/**
 * 新增服务类目
 */
export let addServiceCategory = {
  url: "compose/addServiceCategory",
  method: "post"
}

/**
 * 查询协议列表
 */
 export let queryProtocol = {
  url: "protocol/queryList",
  method: "get"
}

/**
 * 更新服务类目
 */
export let updateProtocol = {
  url: "protocol/update",
  method: "post"
}

/**
 * 删除服务类目
 */
export let delProtocol = {
  url: "protocol/del",
  method: "get"
}

/**
 * 新增服务类目
 */
export let addProtocol = {
  url: "protocol/add",
  method: "post"
}


// ########################### 系统管理 #################

/**
 * 获取所有的菜单
 */
export let queryAllMenu = {
  url: "menu/listByPage",
  method: "post"
}

/**
 * 获取菜单树
 */
export let queryMenuTree = {
  url: "menu/getAllMenuForTree",
  method: "get"
}

/**
 * 新增菜单
 */
export let addMenu = {
  url: "menu/add",
  method: "post"
}

/**
 * 删除菜单
 */
export let removeMenu = {
  url: "menu/del",
  method: "get"
}

/**
 * 编辑菜单
 */
export let updateMenu = {
  url: "menu/update",
  method: "post"
}

/**
 * 分页查询系统角色
 */
export let queryRoleByPage = {
  url: "role/queryByPage",
  method: "post"
}

/**
 * 删除系统角色
 */
export let delSystemRole = {
  url: "role/del",
  method: "get"
}

/**
 * 查询角色详情
 */
export let queryRoleDetail = {
  url: "role/getRoleInfo",
  method: "get"
}

/**
 * 新增角色
 */
export let addRole = {
  url: "role/add",
  method: "post"
}

/**
 * 更新角色
 */
export let updateRole = {
  url: "role/update",
  method: "post"
}

/**
 * 分页查询用户列表
 */
export let queryUserList = {
  url: "user/queryByPage",
  method: "post"
}

/**
 * 查询所有角色
 */
export let getAllRoles = {
  url: "role/queryAllRole",
  method: "get"
}

/**
 * 查询用户详情
 */
export let getUserInfo = {
  url: "user/getUserInfo",
  method: "get"
}

/**
 * 新增用户
 */
export let addUser = {
  url: "user/add",
  method: "post"
}

/**
 * 更新用户信息
 */
export let updateUser = {
  url: "user/update",
  method: "post"
}

/**
 * 删除用户
 */
export let delUser = {
  url: "user/del",
  method: "get"
}

/**
 * 查询所有可用的用户头像
 */
export let queryAllAvatar = {
  url: "user/queryAllAvatar",
  method: "get"
}

// ########################### 上传文件 #################

export let uploadFile = {
  url: "upload/file",
  method: "post",
  timeout: 0
}

export let download = {
  url: "upload/download",
  method: "get",
  // 下载文件必须写responseType:"blob"否则会总成文件损坏
  responseType: "blob",
  timeout: 0
}
