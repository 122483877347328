import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import request,{ baseUrl } from "@/utils/request";
import { queryOrderDetail, queryOrderTrace, addOrderTrace, delOrderTrace } from "@/utils/api";
import { Card, Space, Steps, Descriptions, Tag, Table, Avatar, Button, Modal, Timeline, Input, Divider, List, message, DatePicker, Upload } from "antd";
import {
  LoadingOutlined,
  SmileOutlined,
  AlipayOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  LinkOutlined
} from "@ant-design/icons";
import * as styles from "./detail.module.scss";
import loading from '@/utils/loading'
import { downloadFile } from '@/utils/download'
const { Step } = Steps;

function OrderDetail() {
  let { orderNo } = useParams();

  const [orderDetail, setOrderDetail] = useState({});
  const [orderTrace, setOrderTrace] = useState([]);
  const [visible, setVisible] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [newProgress,setNewProgress] = useState("");
  const [progressTime,setProgressTime] = useState("");
  // 轨迹附件
  const [file,setFile] = useState("");
  // 常用进度句式
  const [exampleProgress,setExampleProgress] = useState([
    "脚本已经编写完毕",
    "安排演员、道具组",
    "布置场景",
    "开机拍摄",
    "后期剪辑、处理",
    "交付样品",
    "交付成品"
  ])

  useEffect(() => {
    getOrderDetail()
    getOrderTrace()
  }, []);

  /**
   * 查询订单详情
   */
  async function getOrderDetail() {
    loading(true)
    let { data: { code, data } } = await request({ ...queryOrderDetail, params: { orderNo } });
    loading(false)
    if (code == 200) {
      setOrderDetail(data);
    }
  }

  /**
   * 查询订单轨迹
   * @param {*} status
   * @returns 
   */
  async function getOrderTrace() {
    let { data: { code, data } } = await request({ ...queryOrderTrace, params: { orderNo } });
    if (code == 200) {
      setOrderTrace(data);
    }
  }

  /**
   * 格式化订单状态
   * @param {*} status
   */
  function formatOrderStatus(status) {
    let allStatus = [
      <Tag color="red">待付款</Tag>,
      <Tag color="orange">进行中</Tag>,
      <Tag color="green">已完成</Tag>,
    ];
    return allStatus[parseInt(status)];
  }

  /**
   * 格式化场地信息展示方式
   * @param {Object} records
   * @returns react el
   */
  function formatSite(records) {
    let { needSite, siteDesc } = records;
    if (needSite == 0) {
      return <div>无需场地</div>;
    } else if (needSite == 1) {
      return <div>{siteDesc}</div>;
    }
  }

  /**
   * 格式化设备信息
   * 格式化最终结果：xxx类型：xxx名称(2个)
   */
  function formatDevice(devices) {
    if(devices && devices.length > 0) {
      let str = devices.map((device) =>`${device.deviceCategoryName}：${device.name}（数量：${device.count}个）`).join("、");
      return <div>{str}</div>
    } else {
      return <div></div>;
    }
  }

  /**
   * 格式化演员信息
   * 格式化最终结果：xxx类型：(2个)
   */
  function formatActor(actors) {
    if(actors && actors.length > 0) {
      let str = actors.map((actor) => `${actor.typeName}：${actor.count}人`).join("、");
      return <div>{str}</div>
    } else {
      return <div></div>;
    }
  }

  /**
   * 格式化后期处理信息
   * 格式化最终结果：xxx处理方式-时长(单位：分钟/秒)
   */
  function formatAfterHandle(afterHandles) {
    if(afterHandles && afterHandles.length > 0) {
      let str = afterHandles.map((afterHandle) =>`${afterHandle.handleTypeName}：${afterHandle.duration ? afterHandle.duration : 0}${afterHandle.timeUnit ? afterHandle.timeUnit : "--"}`).join("、");
      return <div>{str}</div>
    } else {
      return <div></div>;
    }
  }

  /**
   * 关闭弹窗
   */
  function handleCloseModal() {
    setVisible(false)
  }

  /**
   * 更新进度
   */
  async function updateProgress() {
    if(!newProgress) {
      message.warning("请输入进度信息！")
      return;
    }
    let {data:{code,msg}} = await request({...addOrderTrace,data:{orderNo,tips:newProgress,createTime:progressTime,file:file}})
    if(code == 200) {
      message.success("更新成功!")
      getOrderTrace()
      setNewProgress("")
      setShowUpdateModal(false)
    } else {
      message.success(msg || "更新失败")
    }
  }

  /**
   * 删除进度
   */
  function delProgress(id) {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '是否确认删除该进度?',
      okText: '确认',
      cancelText: '取消',
      onOk: async (close)=>{
        let {data:{code,msg}} = await request({...delOrderTrace,params: {orderTraceId:id}})
        if(code == 200) {
          message.success("删除成功!")
          getOrderTrace()
        } else {
          message.success(msg || "删除失败")
        }
        close()
      },
      onCancel:(close)=>{
        close()
      }
    })
  }

  /**
   * 监听上传附件返回值
   */
  function handlerChangeUpload({file}) {
    let { status } = file
    if(status === "done") {
        let { response: { data } } = file
        let url = new URL(data)
        let finalUrl = data.replaceAll(url.search,"")
        setFile(finalUrl)
    }
  }

  return (
    <div>
      <Space size={[0, 20]} direction="vertical" className={styles.space}>
        <Card title="订单状态：" size="small" extra={ orderDetail.orderStatus == 1 ? <Button type="primary" size="small" onClick={()=>setVisible(true)}>查看/更新进度</Button> : null }>
          <Steps current={orderDetail.orderStatus}>
            <Step title="待付款" description="等待客户付款" icon={<AlipayOutlined />}/>
            <Step title="进行中" description="安排场地、制作脚本..." icon={<LoadingOutlined />}/>
            <Step title="已完成" description="已交付成品给客户" icon={<SmileOutlined />} />
          </Steps>
        </Card>
        <Card title="订单明细：" size="small">
          <Descriptions bordered>
            <Descriptions.Item label="客户昵称：">{orderDetail.nickName}</Descriptions.Item>
            <Descriptions.Item label="客户手机号：">{orderDetail.phone}</Descriptions.Item>
            <Descriptions.Item label="订单编号：">{orderDetail.orderNo}</Descriptions.Item>
            <Descriptions.Item label="订单状态：">{formatOrderStatus(orderDetail.orderStatus)}</Descriptions.Item>
            <Descriptions.Item label="订单创建时间：">{orderDetail.createTime}</Descriptions.Item>
            <Descriptions.Item label="视频类型：">{orderDetail.serviceCategory ? orderDetail.serviceCategory.title:""}</Descriptions.Item>
            <Descriptions.Item label="开拍时间：">{orderDetail.shootTime}</Descriptions.Item>
            <Descriptions.Item label="视频总时长：">{orderDetail.duration}{orderDetail.timeUnit}</Descriptions.Item>
            <Descriptions.Item label="场地要求：">{formatSite(orderDetail)}</Descriptions.Item>
            <Descriptions.Item label="演员要求：">{formatActor(orderDetail.actors)}</Descriptions.Item>
            <Descriptions.Item label="后期要求：">{formatAfterHandle(orderDetail.afterHandles)}</Descriptions.Item>
            <Descriptions.Item label="支付金额：">{<div className={styles.payMoney}>￥{orderDetail.totalPrice}</div>}</Descriptions.Item>
          </Descriptions>
        </Card>
        <Card title="设备列表：" size="small">
          <Table bordered dataSource={orderDetail.devices} rowKey="id" size="small" pagination={false}>
            <Table.Column title="设备图片" align="center" dataIndex="img" render={(img)=>
              <Avatar src={img} size={60}></Avatar>
            }></Table.Column>
            <Table.Column title="skuCode" dataIndex="skuCode" align="center"></Table.Column>
            <Table.Column title="设备名称" dataIndex="name" align="center"></Table.Column>
            <Table.Column title="设备类型" dataIndex="deviceCategoryName" align="center"></Table.Column>
            <Table.Column title="设备价格" dataIndex="price" align="center" render={(price)=><div>￥{price}</div>}></Table.Column>
          </Table>
        </Card>
      </Space>
      {/* 查看或者更新进度的弹窗 */}
      <Modal width="40%" title="查看进度" visible={visible} okText="确认" cancelText="取消" onCancel={handleCloseModal} onOk={handleCloseModal}>
          <Card className={styles.traceCard} title="进度明细：" bodyStyle={{paddingTop:'50px'}} size="small" extra={<Button type="primary" size="small" onClick={()=>setShowUpdateModal(true)}>更新进度</Button>}>
            <Timeline mode="right">
              <Timeline.Item className={styles.traceItem} x-for={ (trace,index) in orderTrace } key={trace.id} color={ index == 0 ? 'blue':'green' }
              label={
                <div className={styles.traceOperate}>
                  <div className={styles.delProgress}>
                    {trace.createTime}
                    <div className={styles.delBtn} onClick={()=>delProgress(trace.id)}>[删除记录]</div>
                  </div>
                  { trace.file &&
                    <div className={styles.accessory}>
                      附件：<Space size={[10]}>
                          <LinkOutlined />
                          <span className={styles.downloadFile} onClick={()=>downloadFile(trace.file)}>点击下载附件</span>
                      </Space>
                    </div>}
                </div>
              }>{trace.tips}</Timeline.Item>
            </Timeline>
          </Card>
      </Modal>
      {/* 更新进度弹窗 */}
      <Modal width="35%" title="更新进度" visible={showUpdateModal} okText="确认" cancelText="取消" onCancel={()=>setShowUpdateModal(false)} onOk={updateProgress}>
          <Space size={[20,20]} className={styles.space} direction="vertical">
            <Input.TextArea placeholder="输入进度明细" value={newProgress} onChange={({target:{value}})=>setNewProgress(value)}></Input.TextArea>
            <DatePicker showTime onOk={(value)=>setProgressTime(value)} placeholder="选择进度时间"/>(不选择时间然后确认，表示使用当前时间)
            <Upload action={`${baseUrl}upload/file`} maxCount={1} onChange={handlerChangeUpload}>
              <Button icon={<UploadOutlined/>}>上传附件</Button>
            </Upload>
          </Space>
          <Divider orientation="center" dashed>常用句式(点击进行使用)</Divider>
          <List
            size="small"
            bordered
            dataSource={exampleProgress}
            renderItem={item => (
              <List.Item><div className={styles.exampleProgress} onClick={()=>setNewProgress(item)}>{item}</div></List.Item>
            )}
          />
      </Modal>
    </div>
  );
}

export default OrderDetail;
