function setStore(key,value) {
    window.sessionStorage.setItem(key,value)
}

function getStore(key) {
    return window.sessionStorage.getItem(key)
}

function removeStore(key) {
    return window.sessionStorage.removeItem(key)
}

function getToken() {
    return window.sessionStorage.getItem("token")
}

function clean() {
    window.sessionStorage.clear()
}

export {
    setStore,
    getStore,
    getToken,
    removeStore,
    clean
}