/**
 * ############## 随机数工具类 #################
 */
import Chance from "chance"

const chance = new Chance()

export const getGuid = () => chance.guid()

export const getString = () => chance.string()

export const getSkuCode = () => chance.word({ length: 5 }).toLocaleUpperCase() + chance.timestamp()
