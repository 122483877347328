
import { Menu } from 'antd'
import IconFont from '@/icons/index'
import React from 'react';
import * as lodash from 'lodash'

const { SubMenu } = Menu;

/**
 * 通过路由构建菜单,因为可能存在子路由path相同的情况，所以Menu的key应该放置路由对应的绝对路径
 * @param {Array} routes
 */
let findTrace = []
function buildMenu(routes) {
    let allMenu = routes.map(route=>{
        findTrace.push(route.path)
        if(route.children && route.children.length > 0) {
            return (<SubMenu key={route.path} title={route.title} icon={<IconFont type={route.meta.icon}/>}>
                { buildMenu(route.children) }
            </SubMenu>)
        } else {
            let absolutePath = findTrace.join("/")
            findTrace.pop()
            return !route.meta.hiddenInMenu && <Menu.Item key={absolutePath} icon={<IconFont type={ route.meta.icon }/>} title={route.title}>{route.title}</Menu.Item>
        }
    })
    findTrace = []
    return allMenu
}

/**
 * 相对路由转换为绝对路由链路
 * @param {Array} trace 路由链路
 * @param {String} currentRoute 当前path
 */
function pathToAbsolute(trace,currentPath) {
    if(currentPath) {
        let tracePathArr = trace.map(item=>item.path)
        tracePathArr.push(currentPath)
        return tracePathArr.join("/")
    }
    return trace.map(item=>item.path).join("/")
}

/**
 * 判断路由path和浏览器pathname是否相等(可以判断:id等参数)
 * @param { String } path 路由中配置的path选项
 * @param { String } pathname 当前浏览器地址栏的pathname
 */
function comparePath(path,pathname) {
    let pathNodes = path.split("/")
    let pathnameNodes = pathname.split("/")
    if(pathNodes.length !== pathnameNodes.length) return false;
    return pathNodes.every((pathNode,index)=>{
        if(pathNode.startsWith(":")) {
            return true;
        } else if(pathNode === pathnameNodes[index]) {
            return true;
        } else {
            return false;
        }
    })
}

/**
 * 验证当前访问路由是否存在，并构建该路由的查找链路
 * @param {Array} routes 用户拥有的所有路由
 * @param {String} pathname 当前访问的路由 （浏览器地址：所以pathname都是绝对路径）
 * @param {Array} findTrace 当前判定路由的查找链路，用于构建Route的path
 */
let pathTrace = []
function routeIsExits(routes,pathname) {
    for (let index = 0; index < routes.length; index++) {
        const route = routes[index];
        // 判断当前路由在配置的时候，采用的是【相对路径】还是【绝对路径】
        if(route.path.startsWith("/")) {
            // 绝对路径，直接比对 pathname和route.path是否相等
            if(comparePath(route.path,pathname)) {
                pathTrace.push(route)
                return route
            }
        } else {
            // 相对路径，则需要先转换为绝对路由，然后和pathname进行比对
            let absulutePath = pathToAbsolute(pathTrace,route.path)
            if(comparePath(absulutePath,pathname)) {
                pathTrace.push(route)
                return route
            }
        }
        if(route.children && route.children.length > 0) {
            pathTrace.push(route)
            // 如果找到，则返回
            let findRes = routeIsExits(route.children,pathname)
            if(findRes)  {
                return findRes
            } else {
                // 子路由中没有查到，则剔除存储的父级路由链
                pathTrace.pop()
            }
        }
    }
    // 没有找到目标元素，返回null
    return null
}

/**
 * 判断当前路由是否存在父路由，有则返回
 */
function findRouteTrace(routes,pathname) {
    pathTrace = []
    let findResult = routeIsExits(routes,pathname)
    return { findResult, pathTrace }
}

/**
 * 根据路由构建菜单树
 */
function buildPermissionRecursion(routes, routeTrace = []) {
    for (let index = 0; index < routes.length; index++) {
        const route = routes[index]
        route.key = routeTrace.length > 0 ? `${routeTrace.map(innerRoute=>innerRoute.path).join("/")}/${route.path}`: route.path
        if(route.children && route.children.length > 0) {
            routeTrace.push(route)
            buildPermissionRecursion(route.children,routeTrace)
        }
    }
    routeTrace.pop()
}

/**
 * 通过查询的动态路由构建权限资源树
 * admin超级管理员，自动携带 "系统管理" 菜单
 */
function buildPermissionTreeByRoutes(routes) {
    let copyRoutes = lodash.cloneDeep(routes)
    buildPermissionRecursion(copyRoutes)
    // 构建结果
    return copyRoutes
}


/**
 * 根据path查找目标路由
 * @param {String} targetPath 要查找的path
 * @param {Array} routes 路由集合
 */
function findTargetByPath(targetPath,routes) {
    for (let index = 0; index < routes.length; index++) {
        const route = routes[index]
        if(route.path === targetPath) {
            return route
        }
        if(route.children && route.children.length > 0) {
            let res = findTargetByPath(targetPath,route.children)
            if(res) return res
        }
    }
}

/**
 * 获取变量类型
 */
function getType(obj) {
    let res = Object.prototype.toString.call(obj)
    return res.substring(res.indexOf(" ")+1,res.lastIndexOf("]"));
}

/**
 * 清空对象属性[不支持对象嵌套]
 * @param {Object} obj 要操作的对象
 * @param { initProps } 初始化属性值（这些属性赋予初始值）
 */
function initObjProp(obj,initProps) {
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            let propsVal = obj[key]
            let propsType = getType(propsVal)
            switch (propsType) {
                case 'String':
                    obj[key] = "";
                    break;
                case 'Number':
                    obj[key] = 0;
                    break;
                case 'Object':
                    obj[key] = {};
                    break;
                case 'Boolean':
                    obj[key] = false;
                    break;
                case 'Function':
                    obj[key] = null;
                    break;
                case 'Array':
                    obj[key] = [];
                default:
                    break;
            }
        }
    }
    return {...obj,...initProps}
}


/**
 * 获取文件名称
 * @param {*} url 文件的网络地址
 * @returns 文件名称
 */
function getFileName(url) {
    if (!url) {
        return ""
    }
    let targetUrl = new URL(url)
    let pathNameList = targetUrl.pathname.split("/")
    return pathNameList[pathNameList.length - 1]
}

/**
 * 拆解菜单树为数组
 */
function flattenMenuTree(menuTree,allMenu = []) {
    menuTree.forEach(menu=>{
        allMenu.push(menu)
        if(menu.children && menu.children.length > 0) {
            flattenMenuTree(menu.children,allMenu)
        }
    })
    return allMenu
}

/**
 * 拷贝属性值
 *
 */
function copyProps(source,target) {
    for (const key in source) {
        if (Object.hasOwnProperty.call(source, key) && Object.hasOwnProperty.call(target, key)) {
            source[key] = target[key]
        }
    }
    return source
}

/**
 * 简化规格展示
 * 例：`[{"name":"123","age":66},{"color":"black"}]` ====> 'name:123 | age:66 、color:black '
 */
function jsonStrArr2Str(str) {
    let arr = JSON.parse(str)
    return arr.map(item=>{
        let strBuilder = ""
        for (const key in item) {
            if (Object.hasOwnProperty.call(item, key)) {
                const value = item[key]
                strBuilder+=`${key}:${value} | `
            }
        }
        return strBuilder.substring(0,strBuilder.lastIndexOf("|"))
    }).join("、")
}

export {
    buildMenu,
    routeIsExits,
    findRouteTrace,
    buildPermissionTreeByRoutes,
    findTargetByPath,
    initObjProp,
    flattenMenuTree,
    getFileName,
    copyProps,
    jsonStrArr2Str
}