import { download } from '@/utils/api'
import request from '@/utils/request'

/**
 * 下载文件
 * @param {String} fileUrl
 */
export async function downloadFile(fileUrl) {
    try {
        let url = new URL(fileUrl)
        let pathname = url.pathname
        let fileName = pathname.substring(pathname.lastIndexOf("/")+1,pathname.lastIndexOf("."))
        let { data,headers } = await request({...download,params: { url:fileUrl }})
        let blob = new Blob([data],{ type: headers["content-type"]})
        let objUrl = URL.createObjectURL(blob)
        let aNode = document.createElement("a")
        aNode.href = objUrl
        aNode.download = fileName
        aNode.click()
        URL.revokeObjectURL(objUrl)
    } catch (err) {
        console.log("下载文件异常：", err)
    }
}
