import { getDeviceDetail, queryDeviceCategoryTree, updateDevice, queryAllAttrCategory, addDeviceAttr, delDeviceAttr, updateDeviceAttr } from "@/utils/api"
import request, { baseUrl } from "@/utils/request"
import { useParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { Avatar, Button, Card, Modal, Space, Table, Form, Input, InputNumber, Cascader, Upload, message, Switch, Select, Divider } from "antd"
import { EditOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import * as styles from "./detail.module.scss"
import LuckyEditor from "@/components/editor/editor"
import Viewer from "react-viewer"
import * as lodash from "lodash"
import { getFileName, copyProps, initObjProp, jsonStrArr2Str } from "@/utils/util"
import { getString, getSkuCode } from "@/utils/random"
import loading from '@/utils/loading'

const { Column } = Table
const { Option } = Select

function DeviceDetail() {
    const { id } = useParams()
    const [deviceInfo, setDeviceInfo] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [showBasicModal, setShowBasicModal] = useState(false)
    const [editor, setEditor] = useState({})
    // 0：新增 1：编辑
    const [addOrEditSku, setAddOrEditSku] = useState(0)
    // 所有的分类
    const [allCategory, setAllCategory] = useState([])
    // 设备主图文件
    const [fileList, setFileList] = useState([])
    // 规格图文件
    const [skuFileList, setSkuFileList] = useState([])
    // 图片预览
    const [showPreview, setShowPreview] = useState(false)
    const [previewImgs, setPreviewImgs] = useState([{ src: "", alt: "" }])
    // 记录当前子分类的查询层级
    const typeHierarchy = useRef([])
    // 基础信息form元素
    const basicForm = useRef(null)
    // 所有的属性分类
    const [allAttrType, setAllAttrType] = useState([])
    // 选择的属性分类对应的属性值列表
    const [attrValueList, setAttrValueList] = useState([])
    // 当前操作的规格对象
    const currentSku = useRef(null)

    // ######### 待提交参数变量 #########
    const [basicInfoParams, setBasicInfoParams] = useState({
        id: null,
        name: "",
        categoryId: null,
        rp: "",
        pic: "",
        putaway: 0
    })

    const [skuInfoParams, setSkuInfoParams] = useState({
        id: null,
        skuCode: "",
        spData: [],
        price: 1,
        img: "",
        deviceId: null,
        attrCategoryId: null
    })

    useEffect(() => {
        queryDeviceDetail()
        queryAllCategory()
        getAllAttrCategory()
    }, [])

    useEffect(() => {
        if (Object.keys(editor).length > 0 && deviceInfo.richText) {
            editor.setContent(deviceInfo.richText)
        }
    }, [editor.setContent, deviceInfo.richText])

    // 监听编辑规格，设置默认值
    useEffect(()=>{
        if(showModal && addOrEditSku == 1) {
            let activeData = lodash.cloneDeep(currentSku.current)
            let { spData } = activeData
            activeData.spData = JSON.parse(spData)
            let temp = copyProps(skuInfoParams,activeData)
            setSkuInfoParams(temp)
             // 设置上传组件默认值
            let uploadFile = {
                name: "",
                percent: 100,
                status: "done",
                thumbUrl: "",
                uid: getString(),
                url: "",
                response: { data: "" }
            }
            setSkuFileList([{ ...uploadFile, name: getFileName(activeData.img), thumbUrl: activeData.img, url: activeData.img, response: { data: activeData.img } }])
            let attrValueListObj = activeData.spData.map(item=>{
                let key = Object.keys(item)[0]
                return { name:key,value:item[key], id: `${Date.now()}${Math.floor(Math.random() * 9999)}` }
            })
            setAttrValueList(attrValueListObj)
        }
    },[showModal,addOrEditSku])

    /**
     * 监听基本信息弹窗打开
     */
    useEffect(() => {
        // 设置默认值
        if (showBasicModal && basicForm.current) {
            // 获取默认选中分类
            typeHierarchy.current = []
            getHierarchy(allCategory, deviceInfo.deviceCategory.id)
            let uploadFile = {
                name: "",
                percent: 100,
                status: "done",
                thumbUrl: "",
                uid: getString(),
                url: "",
                response: { data: "" }
            }
            setFileList([{ ...uploadFile, name: getFileName(deviceInfo.pic), thumbUrl: deviceInfo.pic, url: deviceInfo.pic, response: { data: deviceInfo.pic } }])
            let copyData = lodash.cloneDeep(deviceInfo)
            setBasicInfoParams(copyProps(basicInfoParams, copyData))
            basicForm.current.setFieldsValue({
                name: deviceInfo.name,
                rp: deviceInfo.rp,
                categoryId: typeHierarchy.current
            })
            basicForm.current.validateFields(["pic"])
        }
    }, [showBasicModal])

    /**
     * 从分类树中，查询出当前设备分类的层级
     */
    function getHierarchy(allType, targetType) {
        for (let index = 0; index < allType.length; index++) {
            const type = allType[index]
            if (type.id == targetType) {
                typeHierarchy.current.push(type.id)
                return true
            }
            let { children } = type
            if (children && children.length > 0) {
                typeHierarchy.current.push(type.id)
                let res = getHierarchy(children, targetType)
                if (!res) {
                    typeHierarchy.current.pop()
                }
            }
        }
        return false
    }

    /**
     * 查询设备详情
     */
    async function queryDeviceDetail() {
        loading(true)
        let res = await request({ ...getDeviceDetail, params: { id: id } })
        loading(false)
        if (res && res.data.code == 200) {
            setDeviceInfo(res.data.data)
        }
    }

    /**
     * 查询所有分类
     * @param {}} file
     * @returns
     */
    async function queryAllCategory() {
        let {
            data: { code, data }
        } = await request({ ...queryDeviceCategoryTree })
        if (code == 200 && data) {
            function build(data) {
                data.forEach((type) => {
                    type.label = type.name
                    type.value = type.id
                    let { children } = type
                    if (children && children.length > 0) {
                        build(children)
                    }
                })
            }
            build(data)
            setAllCategory(data)
        }
    }

    /**
     * 监听上传图片之前
     * @param {*} file
     * @returns
     */
    function beforeUpload(file) {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
        if (!isJpgOrPng) {
            message.error("仅支持 JPG/PNG 文件!")
        }
        const isLt20M = file.size / 1024 / 1024 < 20
        if (!isLt20M) {
            message.error("单张图片不能超过20M!")
        }
        return isJpgOrPng && isLt20M
    }

    /**
     * 监听上传主图
     */
    function handlePicChange({ file, fileList }, flag) {
        let { status } = file
        if (status == "done") {
            let {
                response: { data }
            } = file
            if (flag === "main") {
                setBasicInfoParams({ ...basicInfoParams, pic: data })
                basicForm.current.validateFields(["pic"])
            }
            if (flag === "sku") {
                setSkuInfoParams({ ...skuInfoParams, img: data })
            }
        }
        if (status == "removed") {
            if (flag === "main") {
                setBasicInfoParams({ ...basicInfoParams, pic: "" })
                basicForm.current.validateFields(["pic"])
            }
            if (flag === "sku") {
                setSkuInfoParams({ ...skuInfoParams, img: "" })
            }
        }
        if (flag === "main") setFileList(fileList)
        if (flag === "sku") setSkuFileList(fileList)
    }

    /**
     * 图片预览
     */
    function doPreview(url) {
        setPreviewImgs([{ src: url, alt: "预览图" }])
        setShowPreview(true)
    }

    /**
     * 校验主图
     */
    function validatePic(rules, fileList) {
        let { message } = rules
        if (fileList.length > 0) {
            return Promise.resolve()
        } else {
            return Promise.reject(new Error(message))
        }
    }

    /**
     * 编辑基础信息
     */
    function doEditBasic() {
        basicForm.current.validateFields().then(async () => {
            let {
                data: { code, msg }
            } = await request({ ...updateDevice, data: basicInfoParams })
            if (code == 200) {
                message.success("更新成功")
                setShowBasicModal(false)
            } else {
                message.warning(msg)
            }
        })
    }

    /**
     * 更新商品的富文本
     */
    async function updateRichText() {
        let content = editor.getContent()
        if (!content) {
            message.warning("请输入详细信息")
            return
        }
        let {
            data: { code, msg }
        } = await request({ ...updateDevice, data: { id: deviceInfo.id, richText: content } })
        if (code == 200) {
            message.success("更新成功")
        } else {
            message.warning(msg)
        }
    }

    /**
     * 查询属性分类列表
     */
    async function getAllAttrCategory() {
        let {
            data: { code, data }
        } = await request({ ...queryAllAttrCategory })
        if (code == 200) {
            setAllAttrType(data)
        }
    }

    /**
     * 编辑规格
     */
    function editSku(record) {
        currentSku.current = record
        setAddOrEditSku(1)
        setShowModal(true)
    }

    /**
     * 关闭弹窗，并初始化数据
     */
    function initData() {
        setSkuInfoParams(initObjProp(skuInfoParams, { id: null, price: 1, deviceId: null, attrCategoryId: null }))
        setSkuFileList([])
        setAttrValueList([])
        setShowModal(false)
    }

    /**
     * 监听属性类型变更
     */
    function changeAttrType(value) {
        if (value) {
            let res = allAttrType.find((item) => item.id == value)
            setAttrValueList(res.attrCategoryValueList)
            setSkuInfoParams({ ...skuInfoParams, attrCategoryId: value })
        } else {
            setSkuInfoParams({ ...skuInfoParams, spData: [], attrCategoryId:null })
            setAttrValueList([])
        }
    }

    /**
     * 监听属性值输入事件
     */
    function handleAttrValInput(key, value) {
        let spData = skuInfoParams.spData
        let targetIndex = null
        let findRes = spData.find((item, index) => {
            targetIndex = index
            return item.hasOwnProperty(key)
        })
        if (findRes && Object.keys(findRes).length > 0) {
            if (!value) {
                // 删除该元素
                spData.splice(targetIndex, 1)
            } else {
                findRes[key] = value
            }
        } else {
            skuInfoParams.spData.push({ [key]: value })
        }
        setSkuInfoParams({ ...skuInfoParams, spData })
    }

    /**
     * 添加/编辑规格
     */
    async function doAddSku() {
        let { spData, img, price } = skuInfoParams
        if (spData.length == 0 || spData.length < attrValueList.length) {
            message.warning("请选择属性分类，并填写完整的属性值")
            return
        }
        if (!img) {
            message.warning("请上传规格图")
            return
        }
        if (!price) {
            message.warning("请填写价格")
            return
        }
        let finalParams = Object.assign({}, skuInfoParams, { deviceId: id, skuCode: getSkuCode() })
        finalParams.spData = JSON.stringify(finalParams.spData)
        let res
        if (addOrEditSku == 0) {
            res = await request({ ...addDeviceAttr, data: finalParams })
        }
        if (addOrEditSku == 1) {
            res = await request({ ...updateDeviceAttr, data: finalParams })
        }
        let {
            data: { code, msg }
        } = res
        if (code == 200) {
            message.success(addOrEditSku == 0 ? "新增成功" : "编辑成功")
            initData()
            queryDeviceDetail()
        } else {
            message.error(msg)
        }
    }

    /**
     * 删除规格
     */
    function delSku(records) {
        let { deviceId,id } = records
        Modal.confirm({
            title:"提示",
            content: "确认删除该规格?",
            okText: "确认",
            cancelText: "取消",
            icon: <ExclamationCircleOutlined />,
            onOk: async (close)=>{
                let { data: { code,msg } } = await request({...delDeviceAttr,params: { deviceId,attrId:id }})
                if(code == 200) {
                    message.success("删除成功")
                    queryDeviceDetail()
                    close()
                } else {
                    message.error(msg)
                }
            },
            onCancel: (close)=>{
                close()
            }
        })
    }

    return (
        <div>
            <Card title={deviceInfo.name}>
                <div className={styles.space}>
                    <Card
                        className={styles.desc}
                        cover={<img className={styles.deviceImg} src={deviceInfo.pic}></img>}
                        actions={[
                            <div onClick={() => setShowBasicModal(true)}>
                                编辑：
                                <EditOutlined key="edit" />
                            </div>
                        ]}>
                        <Card.Meta
                            title={deviceInfo.name}
                            description={
                                <div>
                                    {deviceInfo.deviceCategory ? <div>分类：{deviceInfo.deviceCategory.name}</div> : null}
                                    <div>{`参考价￥：` + deviceInfo.rp}</div>
                                </div>
                            }></Card.Meta>
                    </Card>
                    <Card
                        title="设备属性列表："
                        size="small"
                        extra={
                            <div>
                                <Button
                                    size="small"
                                    icon={<PlusOutlined />}
                                    type="primary"
                                    onClick={() => {
                                        setAddOrEditSku(0)
                                        setShowModal(true)
                                    }}>
                                    新增规格
                                </Button>
                            </div>
                        }>
                        <Table bordered rowKey="id" dataSource={deviceInfo.deviceAttrs} size="small" pagination={false}>
                            <Column title="ID" align="center" dataIndex="id"></Column>
                            <Column title="skuCode" align="center" dataIndex="skuCode"></Column>
                            <Column title="图片" align="center" dataIndex="img" render={(img) => <Avatar src={img} size={42}></Avatar>}></Column>
                            <Column title="价格" align="center" dataIndex="price"></Column>
                            <Column title="规格详情" align="center" dataIndex="spData" render={(value)=>{
                                return <div>{jsonStrArr2Str(value)}</div>
                            }}></Column>
                            <Column
                                title="操作"
                                align="center"
                                render={(records) => (
                                    <Space size={[20]}>
                                        <Button type="primary" size="small" onClick={() => editSku(records)}>
                                            编辑
                                        </Button>
                                        <Button type="danger" size="small" onClick={() => delSku(records)}>
                                            删除
                                        </Button>
                                    </Space>
                                )}></Column>
                        </Table>
                    </Card>
                </div>
                <Card
                    title="设备详情："
                    size="small"
                    className={styles.editor}
                    extra={
                        <Button type="primary" size="small" onClick={updateRichText}>
                            提交更新
                        </Button>
                    }>
                    <LuckyEditor
                        onInit={(editor) => {
                            setEditor(editor)
                        }}></LuckyEditor>
                </Card>
            </Card>
            {/* 新增、编辑规格的弹窗 */}
            <Modal width="40%" title={addOrEditSku == 0 ? "新增规格" : "编辑规格"} visible={showModal} okText="确认" cancelText="取消" onOk={doAddSku} onCancel={initData}>
                <Select style={{ width: 200 }} onChange={changeAttrType} placeholder="请选择属性分类" allowClear defaultValue={skuInfoParams.attrCategoryId} key={Date.now()}>
                    <Option x-for={item in allAttrType} key={item.id} value={item.id}>
                        {item.name}
                    </Option>
                </Select>
                <Divider></Divider>
                <Space size={[20, 20]} direction="vertical" style={{ width: "100%" }}>
                    <Card title="填写属性值：" size="small" x-if={attrValueList.length > 0}>
                        <Space size={[20]}>
                            <Space size={[10]} x-for={item in attrValueList} key={item.id}>
                                <div>{item.name}：</div>
                                <Input defaultValue={item.value} onInput={({ target: { value } }) => handleAttrValInput(item.name, value)}></Input>
                            </Space>
                        </Space>
                    </Card>
                    <Card title="规格图：" size="small">
                        <Upload name="file" accept="image/*" listType="picture-card" fileList={skuFileList} action={`${baseUrl}upload/file`} beforeUpload={beforeUpload} onPreview={({ response: { data } }) => doPreview(data)} onChange={(e) => handlePicChange(e, "sku")}>
                            {skuFileList.length >= 1 ? null : (
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>上传</div>
                                </div>
                            )}
                        </Upload>
                    </Card>
                    <Card title="价格：" size="small">
                        <InputNumber key={Date.now()} min={1} max={99999999} step={0.1} defaultValue={skuInfoParams.price} onChange={(value) => setSkuInfoParams({ ...skuInfoParams, price: value })}></InputNumber>
                    </Card>
                </Space>
            </Modal>
            {/* 编辑基础信息弹窗 */}
            <Modal title="编辑基础信息" visible={showBasicModal} okText="确认" cancelText="取消" onOk={doEditBasic} onCancel={() => setShowBasicModal(false)}>
                <Form ref={basicForm} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                    <Form.Item label="主图" name="pic" rules={[{ required: true, message: "请上传主图", validator: (rules) => validatePic(rules, fileList) }]}>
                        <Upload name="file" accept="image/*" listType="picture-card" fileList={fileList} action={`${baseUrl}upload/file`} beforeUpload={beforeUpload} onPreview={({ response: { data } }) => doPreview(data)} onChange={(e) => handlePicChange(e, "main")}>
                            {fileList.length >= 1 ? null : (
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>上传</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                    <Form.Item label="设备名称：" name="name" rules={[{ required: true, message: "请输入设备名称" }]}>
                        <Input placeholder="请输入设备名称" allowClear onInput={({ target: { value } }) => setBasicInfoParams({ ...basicInfoParams, name: value })}></Input>
                    </Form.Item>
                    <Form.Item label="分类" name="categoryId" rules={[{ required: true, message: "请选择分类" }]}>
                        <Cascader placeholder="请选择分类" options={allCategory} onChange={(value) => setBasicInfoParams({ ...basicInfoParams, categoryId: value[value.length - 1] })}></Cascader>
                    </Form.Item>
                    <Form.Item label="参考价" name="rp" rules={[{ required: true, message: "请输入参考价" }]}>
                        <InputNumber min={1} max={99999999} step={1} onChange={(value) => setBasicInfoParams({ ...basicInfoParams, rp: value })}></InputNumber>
                    </Form.Item>
                    <Form.Item label="上下架" name="putaway">
                        <Switch checkedChildren="上架" unCheckedChildren="下架" checked={basicInfoParams.putaway == 0} onChange={(e) => setBasicInfoParams({ ...basicInfoParams, putaway: e ? 0 : 1 })}></Switch>
                    </Form.Item>
                </Form>
            </Modal>
            {/* 图片预览 */}
            <Viewer visible={showPreview} onClose={() => setShowPreview(false)} images={previewImgs}></Viewer>
        </div>
    )
}

export default DeviceDetail
