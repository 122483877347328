import React,{ useState,useEffect } from 'react'
import { withRouter,useHistory } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Avatar, Dropdown, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import mainStyles from './main.module.scss'
import { buildMenu,findRouteTrace } from '@/utils/util'
import { clean,getStore } from '@/utils/store'
import { routes } from '@/router/index'
import logo from '@/assets/img/logo_white.png'
import { SketchPicker } from 'react-color'
import { dispatch,IS_ADD_DYNAMIC_ROUTER_STATUS } from '@/redux/store'
import { initRoutes } from '@/router/index'
const { Header, Content, Footer, Sider } = Layout;

function Main(props){
    let history = useHistory()
    const [collapsed,setCollapsed] = useState(false)
    const [userInfo,setUserInfo] = useState({avatar:"",username:""})
    const [openAndSelectKeys,setOpenAndSelectKeys] = useState({ openKeys: [],selectKeys:[] })
    const [currentThemeColor,setCurrentThemeColor] = useState("#FFFFFF")

    useEffect(() => {
      buildUserInfo()
    }, []);

    /**
     * 每一次地址栏链接发生改变就执行
     */
    useEffect(()=>{
      buildDefaultOpenAndSelectKeys()
    },[props.location.pathname])

    /**
     * 构建用户信息
     */
    function buildUserInfo() {
      let tempUserInfo = getStore("userInfo")
      if(tempUserInfo) {
        let theUserInfo = JSON.parse(tempUserInfo)
        setUserInfo({...userInfo,...theUserInfo})
      }
    }

    // 控制菜单的收缩和展开
    const onCollapse = () => {
      setCollapsed(!collapsed)
    }

    /**
     * 根据当前路由，确认打开的菜单项
     * 兼容：子菜单配置相对路由和绝对路由
     */
    function buildDefaultOpenAndSelectKeys() {
      let { location:{ pathname } } = props
      // 查找当前地址栏所匹配的路由链路，路由和菜单一一对应，解析出需要打开的菜单项
      let findTrace = findRouteTrace(routes,pathname).pathTrace
      if(findTrace && findTrace.length > 0) {
        // 因为构建菜单的时候，设置的key为绝对路径，所以pathname即为要选中的项，剩余部分是父级菜单
        let selectKeys = pathname
        findTrace.pop()
        let openKeys = findTrace.map(item=>item.path)
        setOpenAndSelectKeys({openKeys,selectKeys})
      }
    }

    /**
     * 根据当前路由，构建面包屑
     */
    function buildBreadcrumb() {
      let { location:{ pathname } } = props
      let findTrace = findRouteTrace(routes,pathname).pathTrace
      return findTrace
    }

    /**
     * 点击菜单跳转
     */
    function clickMenuItem(event) {
      // 菜单的key存放的是路由的绝对路径，所以可以直接跳转
      let { keyPath } = event
      history.push(keyPath[0])
    }

    /**
     * 监听当前要打开的子菜单项
     * @param {*} openKeys 当前需要打开的菜单项
     */
    function openChange(openKeys) {
      setOpenAndSelectKeys({...openAndSelectKeys,openKeys:openKeys})
    }

    /**
     * 下拉菜单组件
     */
    function DropdownItems() {
      return (
        <Menu className={mainStyles.dropdownMenu} onClick={logout}>
          <Menu.Item key="logout" icon={<LogoutOutlined/>}>退出登录</Menu.Item>
        </Menu>
      )
    }

    /**
     * 注销
     */
    function logout(event) {
      let {key} = event
      if(key === "logout") {
        clean()
        dispatch({ type: IS_ADD_DYNAMIC_ROUTER_STATUS, payload: false })
        initRoutes()
        history.replace("/login")
      }
    }

    /**
     * 切换主题色调
     */
    function changeTheme({hex}) {
      if(hex) {
        window.less.modifyVars({ "@primary-color":hex,"@table-header-bg":hex })
        setCurrentThemeColor(hex)
      }
    }

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className={mainStyles.logo}>
            <img className={mainStyles.logoIcon} src={logo} alt="logo"></img>
            { !collapsed && <div className={mainStyles.logoText}>拉克艺</div> }
          </div>
          <Menu theme="dark" openKeys={openAndSelectKeys.openKeys}
          selectedKeys={openAndSelectKeys.selectKeys} mode="inline"
          onClick={clickMenuItem} onOpenChange={openChange}>
            { buildMenu(routes) }
          </Menu>
        </Sider>
        <Layout className={mainStyles.siteLayout}>
          <Header className={[mainStyles.siteLayoutBackground,mainStyles.header]}>
              { React.createElement(collapsed ? MenuUnfoldOutlined: MenuFoldOutlined ,{
                  style: {fontSize:"24px"},
                  onClick:onCollapse
              }) }
              <div className={mainStyles.headerMenus}>
                <Dropdown arrow overlay={ <SketchPicker disableAlpha color={ currentThemeColor } onChangeComplete={changeTheme}/> } trigger={['hover']} placement="bottomCenter" className={mainStyles.colorspace}>
                  <div className={mainStyles.colorSpaceIconBox} onClick={e=>e.preventDefault()}>
                    <Avatar src="https://file.wooyh.cn/icon/1636971140275.png?x-oss-process=style/lk" size={32}></Avatar>
                  </div>
                </Dropdown>
                <Dropdown arrow overlay={DropdownItems} trigger={['hover']} placement="bottomCenter" className={mainStyles.headerRight}>
                  <div onClick={e=>e.preventDefault()}>
                    {
                      userInfo.avatar ? <Avatar src={ userInfo.avatar } />:<Avatar icon={<UserOutlined />} />
                    }
                    <span className={mainStyles.nickName}>{userInfo.username}</span>
                  </div>
                </Dropdown>
              </div>
          </Header>
          <Content className={mainStyles.contentPanel}>
            <Breadcrumb className={mainStyles.breadcrumb}>
              {
                buildBreadcrumb().map(bread=>{
                  return <Breadcrumb.Item key={bread.path}>{bread.title}</Breadcrumb.Item>
                })
              }
            </Breadcrumb>
            <div className={mainStyles.contentCore}>
              { props.children }
            </div>
          </Content>
          <Footer className={mainStyles.footer}>上海拉克艺文化传播股份有限公司</Footer>
        </Layout>
      </Layout>
    );
}

const routerMain = withRouter(Main)

export default routerMain