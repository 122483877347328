import { Statistic, Row, Col, Card, Space } from "antd"
import { AccountBookFilled, ProfileFilled, DollarOutlined } from "@ant-design/icons"
import ReactECharts from "echarts-for-react"
import styles from "./home.module.scss"
import { useEffect,useState } from 'react'
import request from '@/utils/request'
import { queryYearOrderInfo, queryAllOrderAndMoney } from '@/utils/api'
import loading from '@/utils/loading'

function Home() {

  const [chartOptions,setChartOptions] = useState({
    title: {
      subtext: "",
      bottom: 0,
      left: "center"
    },
    tooltip: {
      show: true,
      formatter: '{b0}：{c0}单'
    },
    xAxis: {
      name: "月份",
      type: 'category',
      data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月','9月','10月','11月','12月']
    },
    yAxis: {
      name: "订单量",
      type: 'value'
    },
    series: [
      {
        data: [],
        type: 'line'
      }
    ]
  })
  const [statisticOrder,setStatisticOrder] = useState({})

  useEffect(()=>{
    buildYearOrderInfo()
    buildAllOrderAndMoney()
  },[])

  /**
   * 查询年度订单
   */
  async function buildYearOrderInfo() {
    loading(true)
    let { data: { code,data } } = await request({...queryYearOrderInfo})
    loading(false)
    if(code == 200) {
      setChartOptions({...chartOptions,title:{ ...chartOptions.title,subtext:`--- ${new Date().getFullYear()}年度订单 ---` },series: [
        {
          data: data,
          type: 'line',
          areaStyle: {}
        }
      ]})
    }
  }

  /**
   * 查询总订单量、订单总额等
   */
  async function buildAllOrderAndMoney() {
    let { data: { code,data } } = await request({...queryAllOrderAndMoney})
    if(code == 200) {
      setStatisticOrder(data)
    }
  }

  return (
    <div>
      <Space size={[20,20]} direction="vertical" className={styles.fullWidth}>
        <Row gutter={[16, 30]}>
          <Col span={8}>
            <Card>
              <Statistic title="总订单" value={statisticOrder.totalOrderCount} prefix={<ProfileFilled />} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="总交易额（RMB）" value={statisticOrder.totalPay} precision={2} prefix={<AccountBookFilled />} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="最高成单额（RMB）" valueStyle={{ color: "#cf1322" }} value={statisticOrder.maxPayOrder} precision={2} prefix={<DollarOutlined />} />
            </Card>
          </Col>
        </Row>
        <Row>
          <Card title="年度订单：" size="small" className={styles.fullWidth}>
            <ReactECharts option={chartOptions} notMerge={true} lazyUpdate={true} style={{ width: "100%", height: 500 }}></ReactECharts>
          </Card>
        </Row>
      </Space>
    </div>
  )
}

export default Home
