/**
 * 官方：https://www.tiny.cloud/my-account/dashboard/
 * 1. 注册账号，获得apiKey
 * 2. 去npm搜索：@tinymce/tinymce-react 然后下载安装
 * 3. 如果出现请求tinymce.min.js缓慢，则可以使用自行托管（也就是下载到本地）
 *      配置方式：https://www.tiny.cloud/docs/integrations/react/
 * 
 * *：注意@tinymce/tinymce-react 中使用的 tinymce版本号，如果下载的tinymce源文件和，@tinymce/tinymce-react中使用的不一致，将会报错；
 * 所以先去 @tinymce/tinymce-react 的package.json文件中查看一下依赖的 tinymce库版本号【默认会查询大版本的最新一版比如：^5xxx,也就是5系列最新一个版本】，然后去npm，下载对应版本的 tinymce 源文件，然后
 * 在public/index.html文件中引入即可
 *  
 * 本项目：
 * 
 * "@tinymce/tinymce-react": "^3.13.0", 对应的 tinymce 版本为：5.10.3
 * 
 */
import { Editor } from "@tinymce/tinymce-react"
import { baseUrl } from '@/utils/request'
import request from '@/utils/request'
import { uploadFile } from '@/utils/api'
import { Button, message, Modal, Upload } from 'antd'
import { useState } from 'react'
import { UploadOutlined  } from '@ant-design/icons'

function LuckyEditor(props) {

    const [visible,setVisible] = useState(false)
    const [editor,setEditor] = useState({})

    /**
     * 监听Editor属性变更
     * @param {String} content 当前内容
     * @param {Object} editor 编辑器实例
     */
    function handleEditorChange(content, editor) {

    }

    /**
     * 监听图片上传
     */
    async function handlerImgUpload(blobInfo, success, failure, progress) {
        let formData = new FormData()
        formData.append("file",blobInfo.blob(),blobInfo.filename())
        let { data:{ code,data, msg } } = await request({...uploadFile,onUploadProgress:(event)=>{
            let { loaded,total } = event
            progress(loaded / total * 100)
        },data:formData})
        if(code == 200) {
            success(data)
        } else {
            failure(msg)
        }
    }

    /**
     * 点击自定义上传媒体文件按钮
     */
    function handlerUploadMedia(api) {
        setVisible(true)
    }

    /**
     * 监听上传文件之前
     */
    function handleBeforeUpload(file,fileList) {
        let verifyRes = true
        let { type,size } = file
        if(type.split("/")[0] !== "video") {
            message.warning("仅支持视频类型文件！")
            verifyRes = false
        }
        if((size / 1024 / 1024) > 300) {
            message.warning("视频文件大小不能超过300MB！")
            verifyRes = false
        }
        if(!verifyRes) {
            return Upload.LIST_IGNORE
        } else {
            return verifyRes
        }
    }

    /**
     * 监听上传媒体文件返回值
     */
    function handlerChangeUpload({file}) {
        let { status } = file
        if(status === "done") {
            let { response: { data } } = file
            let url = new URL(data)
            let videoHtml = `<video controls="controls" width="300" height="150"><source src="${data.replaceAll(url.search,"")}" type="${file.type}" /></video>`
            editor.setContent(`${editor.getContent()}${videoHtml}`)
            setVisible(false)
        }
    }

    return (
        <div>
            {/* 上传媒体文件弹窗 */}
            <Modal title="上传视频文件" visible={visible} okText="确认" cancelText="取消" onCancel={()=>setVisible(false)}>
                <Upload accept="video/*" action={`${baseUrl}upload/file`} beforeUpload={ handleBeforeUpload } onChange={handlerChangeUpload}>
                    <Button icon={<UploadOutlined/>}>上传视频文件</Button>
                </Upload>
            </Modal>
            {/* 采用自托管的方式引入tinymce.min.js;
                不需要使用：apiKey="9wzcj2aupdctewnmi5s8vq4sd395nc60f4gzxojn7wqq6hzn"
                language_url: 自定义引入 public/editor/zh_CN.js
            */}
            <Editor
                id="editor"
                initialValue={props.editInitData ? props.editInitData : ""}
                onEditorChange={handleEditorChange}
                onInit={(e,editor)=>{ setEditor(editor);if(props.onInit) props.onInit(editor) }}
                init={{
                    deprecation_warnings: true,
                    images_upload_url: `${baseUrl}upload/file`,
                    images_upload_handler: handlerImgUpload,
                    images_reuse_filename: true,
                    min_height: 500,
                    max_height: 800 ,
                    language: "zh_CN",
                    language_url: `${process.env.PUBLIC_URL}/editor/zh_CN.js`,
                    fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
                    setup: function(editor) {
                        editor.ui.registry.addIcon('uploadVideo',`<svg t="1636196580507" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3348" width="22" height="22"><path d="M541.9 886H98V109.2h776.8v471.7h-55.5V164.7H153.5v665.8h388.4V886z m0 0" p-id="3349" fill="#000000"></path><path d="M787.6 802.8h137.8L738.6 548.5 551.8 802.8h124.8v111h111v-111z m0 0M616.4 497.6L391.6 321v353.3z" p-id="3350" fill="#000000"></path></svg>`)
                        editor.ui.registry.addButton("uploadMedia",{
                            text: "上传视频文件",
                            tooltip: "上传视频文件",
                            icon: "uploadVideo",
                            onAction:(api)=>{
                                handlerUploadMedia(api)
                            }
                        })
                    }
                }}
                plugins="preview searchreplace autolink directionality visualblocks visualchars fullscreen image link template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave autoresize media"
                toolbar={["code undo redo restoredraft | cut copy paste pastetext | formatpainter forecolor backcolor bold italic underline strikethrough link anchor",
                "alignleft aligncenter alignright alignjustify outdent indent | styleselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | table image media uploadMedia charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight axupimgs"]}></Editor>
        </div>
    )
}

export default LuckyEditor
