import axios from "axios"
import { getToken } from "./store"
import qs from "qs"

// export const domain = "http://localhost:8900/lucky-admin/"
const domain = "https://admin.server.wooyh.cn/lucky-admin/"
// 设置测试环境开启代理  --->(process.env.NODE_ENV可以被webpack的mode更改)，该脚手架中执行start为：development，执行build为：production
export const baseUrl = process.env.NODE_ENV === "development" ? `/proxy${new URL(domain).pathname}` : domain

axios.defaults.baseURL = baseUrl
axios.defaults.timeout = 60 * 1000

axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    }
)

/**
 * 处理后台post+@RequestParam方式接收参数：将post请求的参数序列化
 */
function stringifyData(data) {
    return qs.stringify(data)
}

/**
 * 获取完整链接地址
 * @param {Object} api 请求的地址对象
 * @returns 返回完整的请求地址
 */
export function getWholeUrl(api) {
    if (api.method && api.method.toLowerCase() === "get") {
        return Object.keys(api.params).length > 0 ? `${baseUrl}${api.url}?${qs.stringify(api.params)}` : `${baseUrl}${api.url}`
    } else {
        return `${baseUrl}${api.url}`
    }
}

/**
 * 发送请求
 * @param {*} config axios配置
 * @returns 
 */
export default async function requset(config) {
    let defaultConfig = { headers: { Authorization: getToken() }, ...config }
    if (config.qs) {
        // 默认为: application/json，前端直接发送json数据给后端，后端采用@RequsetBody接收
        defaultConfig.headers["content-type"] = "application/x-www-form-urlencoded"
        defaultConfig.data = stringifyData(config.data)
    }
    return await axios(defaultConfig)
}
