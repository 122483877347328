import { createFromIconfontCN } from "@ant-design/icons"

const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_2785409_wogv42gd2hr.js", // 在 iconfont.cn 上生成
})

// json形式图标
const iconJson = [
    {
        icon_id: "1119363",
        name: "意见反馈",
        font_class: "yijianfankui",
        unicode: "e63c",
        unicode_decimal: 58940,
    },
    {
        icon_id: "658956",
        name: "图片",
        font_class: "tupian",
        unicode: "e67f",
        unicode_decimal: 59007,
    },
    {
        icon_id: "670140",
        name: "YDUI-排版类型(列表)",
        font_class: "yduipaibanleixingliebiao",
        unicode: "e695",
        unicode_decimal: 59029,
    },
    {
        icon_id: "19949528",
        name: "视频",
        font_class: "shipin",
        unicode: "e60a",
        unicode_decimal: 58890,
    },
    {
        icon_id: "20891557",
        name: "签署协议",
        font_class: "qianshuxieyi",
        unicode: "e8ad",
        unicode_decimal: 59565,
    },
    {
        icon_id: "553324",
        name: "验证 验证码",
        font_class: "yanzhengyanzhengma",
        unicode: "e624",
        unicode_decimal: 58916,
    },
    {
        icon_id: "739116",
        name: "首页 (1)",
        font_class: "shouye1",
        unicode: "e638",
        unicode_decimal: 58936,
    },
    {
        icon_id: "3613650",
        name: "后台菜单",
        font_class: "houtaicaidan",
        unicode: "e63e",
        unicode_decimal: 58942,
    },
    {
        icon_id: "19102800",
        name: "账号",
        font_class: "zhanghao",
        unicode: "e620",
        unicode_decimal: 58912,
    },
    {
        icon_id: "20186174",
        name: "密码锁lock",
        font_class: "mimasuolock",
        unicode: "e7c7",
        unicode_decimal: 59335,
    },
    {
        icon_id: "22618562",
        name: "拍照、照相、相机",
        font_class: "paizhao",
        unicode: "e606",
        unicode_decimal: 58886,
    },
    {
        icon_id: "15450076",
        name: "订单管理",
        font_class: "dingdanguanli",
        unicode: "e619",
        unicode_decimal: 58905,
    },
    {
        icon_id: "589511",
        name: "角色管理",
        font_class: "jueseguanli",
        unicode: "e70e",
        unicode_decimal: 59150,
    },
    {
        icon_id: "18379488",
        name: "设 置",
        font_class: "shezhi",
        unicode: "e696",
        unicode_decimal: 59030,
    },
    {
        icon_id: "18828406",
        name: "用户",
        font_class: "user",
        unicode: "e614",
        unicode_decimal: 58900,
    }
]

export default IconFont

export { iconJson }
