/**
 * 路由配置：菜单和路由强关联 [约定规则----->路由中的pathname每一个"/"代表一个菜单分层，之所以强关联目的是为了，方便建立地址栏和菜单的关联关系]
 *
 *      存在问题：
 *
 *             1. 如 pathname = /system/user/123 该如何处理？
 *
 *                      处理方法：====> 其实就是要在导航守卫中实现：isExact的功能，非绝对匹配的时候可以进行xxx/:id形式的参数传递
 *
 *             2. 菜单存在强关联，如果子菜单设置的path为绝对路径，那么地址栏变更后，无法自动打开父级菜单 ？
 *
 *                      处理方法：====>
 *
 *                          手动点击菜单，可以获取到层级关系。如果子菜单是绝对路径，则直接history.push(子路径)即可、如果是相对路径，则拼接成绝对路径后push
 *
 *                          用户更改路由，如果子菜单的path是绝对路径，则仅通过地址栏是不能获取到它的父级菜单对应的key的，也就无法默认打开？ 【可以定义一个方法，用于查找当前子路由是否存在父路由，有则返回】
 *
 * 明细：
 * 1. 强制要求：=====> 每单个路由path不能为"xxx/xxx"的形式【比较难以辨别相对和绝对】
 * 2. 由于使用路由的path充当菜单组件的key,所以路由的嵌套就是菜单的嵌套关系
 * 3. path的配置方式有两种  [绝对地址、相对地址(相对于其父路由)]
 *
 *      绝对路径，在导航守卫进行 routeIsExits判定时，直接和地址栏pathname进行比对
 *      相对路径，在导航守卫进行 routeIsExits判定时，会将相对路径，按照嵌套关系转换为绝对路径后再进行比对
 *
 * 4. 第一级父路由必须是绝对路径 (以"/"开头)
 *
 * component：路由对应的组件（如果没有配置则代表该路径对应的是主菜单，并不需要进行组件渲染）
 *
 * meta： {
 *   exact：是否精准匹配，设置为精准匹配，意味着，同一个时间不会渲染多个组件；只能是匹配一个
 *   hiddenInMenu：是否不在菜单栏展示
 *   icon：菜单图标,填写阿里矢量图中的icon名称
 *   isTab: 是否展示面包屑【因为父路由可能不存在component，所以也就没有必要渲染，因此即使显式面包屑中也没有实际作用，点击也无跳转】
 *
 */
import asyncComponent from "@/utils/_import"
import { dispatch, IS_ADD_DYNAMIC_ROUTER_STATUS } from "@/redux/store"
import Home from "@/pages/home/home"
import Login from "@/pages/login/login"
import DeviceDetail from '@/pages/device/detail/detail'
import OrderDetail from '@/pages/order/detail/detail'
import { cloneDeep } from 'lodash'

// 固定路由部分:[动态菜单由后台配置返回至前端]
export let routes = [
    {
        path: "/",
        title: "首页",
        meta: {
            icon: "icon-shouye1",
            exact: true
        },
        component: Home
    },
    {
        path: "/login",
        title: "登录",
        meta: {
            hiddenInMenu: true,
            exact: true
        },
        compoment: Login
    },
    {
        path: "/device/detail/detail/:id",
        title: "设备详情",
        meta: {
            hiddenInMenu: true,
            exact: true
        },
        component: DeviceDetail
    },
    {
        path: "/order/detail/detail/:orderNo",
        title: "订单详情",
        meta: {
            hiddenInMenu: true,
            exact: true
        },
        component: OrderDetail
    }
]

const copyRoutes = cloneDeep(routes)

/**
 * 构建动态路由的component属性
 * @param {*} dynamicRoutes 获取的动态路由
 */
let routeTrace = []
function buildComponentForDynamicRoute(dynamicRoutes) {
    for (let index = 0; index < dynamicRoutes.length; index++) {
        let route = dynamicRoutes[index]
        let { path } = route
        routeTrace.push(path)
        // 拥有children的都无需构建component
        if (route.children && route.children.length > 0) {
            buildComponentForDynamicRoute(route.children)
        } else {
            // 构建component
            let absolutePath = routeTrace.join("/")
            route.component = asyncComponent(absolutePath)
            // 构建完毕后删除，当前一层级追溯节点
            routeTrace.pop()
        }
    }
    routeTrace = []
}

/**
 * 添加动态路由
 * 处理重复添加：
 *  1. 使用redux创建全局共享变量，如果已经添加过动态菜单。则不重复进行添加
 * @param {Array<Object>} dynamicRoute 后端返回的动态路由
 */
export function addDynamicRoutes(dynamicRoute) {
    if (dynamicRoute && dynamicRoute.length && dynamicRoute.length > 0) {
        buildComponentForDynamicRoute(dynamicRoute)
        routes = routes.concat(dynamicRoute)
        dispatch({ type: IS_ADD_DYNAMIC_ROUTER_STATUS, payload: true })
    }
}

/**
 * 初始化路由为原始值
 * 退出登录，切换账号，对应的菜单不一致，所以需要还原一次routes
 */
export function initRoutes() {
    routes = copyRoutes;
}
