/**
 * 模块动态导入：
 * 由于ReactRouter的component属性，接收的必须是React组件(类组件或者函数组件)。但是import()函数返回的是Promise,
 * 代表是异步的，所以时机不好把控。导致渲染的Route组件无效，所以采用高阶组件包括import()函数，依靠高阶组件的state。去动态的渲染
 * 目标组件
 * 使用函数式组件出现异常（hooks使用不当，暂未找到解决方法，所以采用class组件）： Should have a queue. This is likely a bug in React. Please file an issue
 *
    function AsyncComponent (props) {
        const [TargetComponent, setTargetComponent] = useState(null)
        useEffect(() => {
            console.log("传递的path：",path)
            loadComponent()
        }, []);

        function loadComponent() {
            import(`@/pages${path}`).then(finishComponent=>{
                // 问题出现点=====>
                setTargetComponent(finishComponent.default)
            })
        }

        return (TargetComponent) ? <TargetComponent {...props}></TargetComponent> : null
    }
 *
 */
import React from "react"
const asyncComponent = (path) => (
    class AsyncComponent extends React.Component{
        constructor(props) {
            super(props)
            this.state = {
                TargetComponent:null
            }
        }
        componentDidMount() {
            import(`@/pages${path}`).then(finishComponent=>{
                this.setState({ TargetComponent: finishComponent.default})
            })
        }
        render() {
            const { TargetComponent } = this.state
            return (TargetComponent) ? <TargetComponent {...this.props}></TargetComponent> : null
        }
    }
)

export default asyncComponent
